.Template .task a, .Template .availableTask a {
    color: inherit;
}

.Template .currentTasks, .Template .availableTasks {
    border: thin solid #dddddd;
    padding: 20px;
    max-height: 50vh;
    overflow: scroll;
    border-radius: 10px;
}

.Template .currentTasks {
    /* margin-top: 60px; */
    border: 4px dashed #dddddd;
}

.Template .task, .Template .availableTask {
    /* min-height: 50px; */
    order: thin solid #dddddd;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    background-color: #dddddd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

}


.Template .filterTask {
    margin: 10px 0;
    height: 40px;
    width: 100%;
    padding-left: 10px;
}

.Template .filterTaskButtonWrapper {
    margin-bottom: 10px;
}

.Template .filterTaskButtonWrapper .filterTaskButton {
    margin: 5px;
}

.Template .filterTaskWrapper {
    display: flex;
    flex-direction: row;

}

.Template .filterTaskWrapper button {
    margin: 10px
}