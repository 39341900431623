.PointsTable .point {
    margin: 10px;
    max-width: 400px;
}

.PointsTable .point-contender {
    font-weight: bold;
}

.PointsTable .point-activity {
    font-weight: bold;
    color: grey;
}

.PointsTable .point-note {
    
}