body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

.add-form-wrapper{
  /* margin-bottom: 20px; */
  /* padding-bottom: 30px; */
}

ul {
  list-style: none;
  padding-left: 0;
}

ul.navbar-light li.nav-item a {
  color: white;
}

ul.navbar-light li.nav-item a:hover {
  color: yellow;
}

.tab-content {
  padding: 20px 10px;
}

.heading-wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.heading-wrapper button {
  margin-left: 20px;
  
}

.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}